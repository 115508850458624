const urls = {
    api: 'https://rukh.co.jp/api',
    top: '/',
    aboutRukhFit: '/about-rukh-fit',
    aboutUs: '/about-us',
    contact: '/contact',
    trial: '/trial',
    aboutRukhKids: '/about-rukh-kids',
    aboutTravelingDanceClasses: '/about-traveling-dance-classes',
    aboutAdultHipHop: '/about-adult-hiphop',
    benefitDance: '/benefit-dance',
    privacyPolicy: '/privacy-policy',
    x: 'https://www.x.com',
    instagram: 'https://www.instagram.com/rukh_llc/',
    facebook: 'https://www.facebook.com/profile.php?id=61563851855948&mibextid=LQQJ4d',
    line: 'https://liff.line.me/1645278921-kWRPP32q/?accountId=531azmqk',
}

export default urls;
